import * as React from 'react'

import { Box, Container, Grid, Link, Stack, Typography } from '@mui/material'

import type { TypographyProps } from '@mui/material'

const FooterText = ({
  align = 'justify',
  children,
}: TypographyProps) => (
  <Typography
    align={align}
    component='div'
    variant='caption'
    gutterBottom
  >
    {children}
  </Typography>
)

export const Footer = () => (
  <Box
    sx={{
      bgcolor: 'grey.900',
      color: 'grey.400',
    }}
  >
    <Container
      maxWidth='lg'
      sx={{
        display: 'flex',
        pt: 3,
        pb: 2,
      }}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={3}
        >
          <Stack
            spacing={1}
            sx={(theme) => ({
              textAlign: 'center',
              [theme.breakpoints.up('md')]: {
                textAlign: 'left',
              },
            })}
          >
            <Link
              href='/docs/terminos-y-condiciones.pdf'
              target='_blank'
              rel='noopener'
              color='inherit'
              underline='none'
              fontWeight={500}
            >
              Términos y condiciones
            </Link>
            <Link
              href='/docs/politica-de-privacidad.pdf'
              target='_blank'
              rel='noopener'
              color='inherit'
              underline='none'
              fontWeight={500}
            >
              Política de privacidad
            </Link>
            <Link
              href='/denuncias'
              color='inherit'
              underline='none'
              fontWeight={500}
            >
              Canal de denuncias
            </Link>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
        >
          <FooterText>
            Sugarblock SpA y todas sus empresas filiales no son entidades reguladas
            ante el regulador financiero local. Sin perjuicio de lo anterior,
            mantenemos estándares de cumplimiento normativo basados en la regulación
            vigente en Latinoamérica. Con el objetivo de que la industria se
            desarrolle en forma responsable, es extremadamente importante que todos
            nuestros usuarios sepan:
          </FooterText>
          <FooterText>
            <ol type='i'>
              <li>
                Las criptomonedas no son una moneda de curso legal ni están
                respaldadas por un Banco Central.
              </li>
              <li>
                Una vez completada debidamente una transferencia de criptomonedas,
                es imposible revertirla sin seguir el curso de Sugarblock.
              </li>
              <li>
                Las criptomonedas son activos cuyo precio es alcanzado
                exclusivamente por oferta y demanda, pudiendo ser altamente volátil
                y la rentabilidad ofrecida por Sugarblock no es motivo de garantizar
                precios futuros.
              </li>
              <li>
                Las criptomonedas están afectas a los riesgos tecnológicos,
                cibernéticos y de fraude inherentes a cualquier actividad
                transaccional virtual. Nosotros nos hacemos cargo del concepto de
                custodio de private keys de tus criptomonedas.
              </li>
            </ol>
          </FooterText>
          <FooterText align='center'>
            Cualquier duda adicional contactar a satoshi@sugarblock.io
          </FooterText>
          <FooterText align='center'>
            San Patricio 4298, Oficina 11, Vitacura, Santiago, Chile.
          </FooterText>
          <FooterText align='center'>
            &copy; Sugarblock {new Date().getFullYear()}
          </FooterText>
        </Grid>
      </Grid>
    </Container>
  </Box>
)

export default Footer
