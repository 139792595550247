/* eslint-disable max-len */
import * as React from 'react'

import { useTheme } from '@mui/material'

import type { Theme } from '@mui/material'

const navLogoColor = (theme: Theme) => {
  const palette = theme.palette
  return palette.mode === 'light' ? palette.primary.dark : palette.primary.main
}

export const NavLogo = ({
  href,
}: { href?: string }) => (
  <a
    href={href || '/'}
    style={{ display: 'flex' }}
  >
    <svg
      version='1.1'
      viewBox='0 0 314.97 65.68'
      xmlns='http://www.w3.org/2000/svg'
      width='140px'
    >
      <g fill={navLogoColor(useTheme())}>
        <path d='M295.52,19.33a18.33,18.33,0,0,1,4-3.45,12.15,12.15,0,0,1,10-1.29A7.54,7.54,0,0,1,315,21.76a12.21,12.21,0,0,1-5.06,10.72A18.56,18.56,0,0,1,307,34.36c-.55.27-.59.57-.41,1.1,1.56,4.81,3.09,9.63,4.66,14.44.22.64.17.85-.58.84-3.41,0-6.82,0-10.22,0a.78.78,0,0,1-.92-.68q-2.16-7.83-4.38-15.65c-.19-.66,0-.83.56-1.06a17.61,17.61,0,0,0,5.21-3,7.62,7.62,0,0,0,2.79-5.84c0-2.18-1.51-3.23-3.6-2.5a8.38,8.38,0,0,0-3.29,2.38,23.08,23.08,0,0,0-2.4,3.31,3.27,3.27,0,0,0-.44,1.33c-.79,5.93-1.61,11.85-2.42,17.78-.15,1.08-.3,2.15-.42,3.23-.05.47-.21.67-.72.66-3.3,0-6.59,0-9.89,0-.55,0-.66-.18-.58-.68q1.13-8,2.24-15.95l2.43-17.36q1-7.44,2.06-14.89c.05-.33,0-.67.58-.72,3.33-.31,6.66-.63,10-1,.73-.08.83.14.74.78q-1.26,8.85-2.46,17.7C295.54,18.77,295.54,18.94,295.52,19.33Z' />
        <path d='M15.63,52c-5.8-.07-10.91-1.82-15-6-.8-.82-.8-.82,0-1.63C2.31,42.67,4,41,5.68,39.33c.41-.42.63-.41,1.05,0A12.48,12.48,0,0,0,13.52,43c2.2.31,4.4.36,6.41-.86a5.54,5.54,0,0,0,.65-8.7,21,21,0,0,0-5.24-2.92,28.12,28.12,0,0,1-4.92-2.65c-4-2.75-5.29-6.65-4.78-11.28.8-7.35,6-12,13.3-13.11A20.89,20.89,0,0,1,32,5.25,18,18,0,0,1,37,9c.24.25.28.4,0,.67-1.75,1.77-3.49,3.54-5.2,5.33-.36.38-.53.16-.79-.07a10.86,10.86,0,0,0-6.92-3.16,7,7,0,0,0-4.87,1.11,4.2,4.2,0,0,0,0,6.89,33.34,33.34,0,0,0,5.64,2.89,25.48,25.48,0,0,1,5,2.95,11.18,11.18,0,0,1,4.42,8.71c.49,9.54-6.19,16.14-14.86,17.28C18.2,51.8,16.91,51.87,15.63,52Z' />
        <path d='M54.59,46.17c-.63.7-1.3,1.51-2,2.26A10.5,10.5,0,0,1,44.63,52a7.09,7.09,0,0,1-7.29-6.43,25.68,25.68,0,0,1,.43-7.06C38.51,33,39.28,27.6,40,22.17q.45-3.18.87-6.36c.05-.44.21-.61.68-.61q5,0,9.88,0c.56,0,.62.14.55.67Q50.38,27.48,48.79,39.1a8.8,8.8,0,0,0,0,1.92,1.39,1.39,0,0,0,2.05,1.31A7,7,0,0,0,53.58,40c1.25-1.36,1.47-3.05,1.7-4.77.88-6.48,1.8-12.95,2.68-19.43.07-.48.25-.59.7-.59,3.29,0,6.59,0,9.88,0,.47,0,.61.09.53.61-.84,5.86-1.65,11.73-2.47,17.6-.75,5.4-1.53,10.8-2.26,16.21-.11.82-.31,1.16-1.24,1.13-2.57-.08-5.14,0-7.71,0-.63,0-.87-.11-.83-.82C54.64,48.65,54.59,47.38,54.59,46.17Z' />
        <path d='M252.2,36.66a27.32,27.32,0,0,1,3.73-13.94c3.77-6.17,9.28-9.2,16.59-8.68a13.88,13.88,0,0,1,8.88,4c.39.38.47.61.07,1.06-1.54,1.73-3,3.49-4.52,5.26-.33.4-.49.41-.9.08a7,7,0,0,0-5.82-1.71A5.54,5.54,0,0,0,266.1,26,24.08,24.08,0,0,0,264,39.46c.39,3.11,3,4.41,6.14,3.36a13.28,13.28,0,0,0,3.2-1.59c.41-.27.58-.21.81.2q1.69,3,3.44,5.92c.2.34.17.5-.15.73C271.92,52,266,53.22,259.65,50.57c-4-1.66-6.08-5-7-9.1A26,26,0,0,1,252.2,36.66Z' />
        <path d='M207.77,51.93a8.16,8.16,0,0,1-5.8-2,7.48,7.48,0,0,1-2.25-5.4,38.77,38.77,0,0,1,.75-7c.78-5.79,1.61-11.57,2.42-17.35s1.62-11.68,2.46-17.51c.2-1.47.23-1.49,1.61-1.63,3-.31,6-.58,9-.91.63-.06.75.13.66.71Q215.29,10.16,214,19.5t-2.57,18.76c-.13.94-.26,1.88-.38,2.81-.19,1.47.42,2.09,1.84,1.8.54-.11.66,0,.72.5.25,2.13.51,4.25.82,6.37.08.62-.05.94-.68,1.16A19.28,19.28,0,0,1,207.77,51.93Z' />
        <path d='M154,21.22a16.36,16.36,0,0,1,3.4-4.71,8.3,8.3,0,0,1,8.14-2c.54.14.7.3.51.89q-1.5,4.77-2.91,9.57c-.16.55-.38.65-.95.53-3.13-.7-5,.09-6.59,2.86a23.87,23.87,0,0,0-3.16,9.41c-.48,4.11-1.14,8.21-1.7,12.31-.07.52-.29.67-.81.66-3.24,0-6.47,0-9.71,0-.58,0-.69-.16-.61-.71q1.75-12.56,3.49-25.12c.42-3,.86-6,1.25-9,.07-.51.21-.7.76-.69,2.73,0,5.47,0,8.2,0,.55,0,.71.15.7.7C154,17.6,154,19.3,154,21.22Z' />
        <path d='M103.33,17.3A27.06,27.06,0,0,0,90.61,14c-7.38-.07-13,3-16.59,9.43a27.5,27.5,0,0,0-3.15,11.15A23,23,0,0,0,71.69,44c2.49,7.56,10.2,8,14.55,5.12a14,14,0,0,0,2.9-2.74c-.25,1.72-.44,3.24-.69,4.74-.77,4.59-3.5,6.45-8.23,6a18,18,0,0,1-6.54-2.16c-.46-.24-.62-.2-.85.26q-1.58,3.08-3.23,6.12c-.23.42-.15.59.24.83a22.88,22.88,0,0,0,16.35,3.22,13.91,13.91,0,0,0,10.73-7.75,31,31,0,0,0,2.76-9.65c.71-5,1.4-9.92,2.1-14.88s1.38-9.8,2.09-14.7A.87.87,0,0,0,103.33,17.3ZM92.17,23c-.45,3-.85,6-1.28,9-.24,1.71-.54,3.41-.71,5.12a4.36,4.36,0,0,1-1,2.4A9.78,9.78,0,0,1,86.56,42a2.71,2.71,0,0,1-4.31-1.91,17.45,17.45,0,0,1-.19-3.43A26.17,26.17,0,0,1,84.29,26c.88-1.91,2.13-3.47,4.36-3.9a7.67,7.67,0,0,1,3,.08C92.11,22.3,92.25,22.49,92.17,23Z' />
        <path d='M196.51,20.46a8.69,8.69,0,0,0-10.33-6.32,12.15,12.15,0,0,0-7,3.82c0-.47.05-.74.09-1q1.13-8.17,2.26-16.36c.07-.48,0-.64-.54-.58-3.27.38-6.54.76-9.82,1.08-.67.06-.86.32-.93.92-.39,3-.82,6-1.24,8.93q-1.29,9.3-2.58,18.6t-2.61,18.59a.87.87,0,0,0,.64,1.11,33,33,0,0,0,15.78,2.54A18.46,18.46,0,0,0,188,49.42a16.35,16.35,0,0,0,6.45-7.68,33.15,33.15,0,0,0,2.9-12.26A25.2,25.2,0,0,0,196.51,20.46Zm-10.43,7.41a32.5,32.5,0,0,1-1.93,10.91,11.8,11.8,0,0,1-1.65,3c-1.9,2.45-4.11,2.17-6.47,1.42-.35-.1-.28-.35-.25-.6q1.16-8.22,2.3-16.44a1,1,0,0,1,.08-.4c1.14-1.46,2.29-2.92,4.21-3.39a2.64,2.64,0,0,1,3.41,2.07,4.79,4.79,0,0,1,.18,1A15.39,15.39,0,0,1,186.08,27.87Z' />
        <path d='M138.34,19.19c.22-1.53.24-1.53-1.11-2.25-.55-.28-1.1-.54-1.66-.78a24.7,24.7,0,0,0-14.8-1.76c-5.86,1.17-9.82,4.86-12.33,10.08a32,32,0,0,0-2.83,16.67,13.29,13.29,0,0,0,2.64,7.36c3.17,4,9.5,4.63,13.75,1.32a17.64,17.64,0,0,0,3.21-3.41,1.4,1.4,0,0,1,.16.23c1.36,3.32,4.12,4.54,7.42,5a.66.66,0,0,0,.82-.47c.94-2.11,1.91-4.2,2.86-6.3.1-.21.28-.44-.06-.64-1.3-.79-1.3-2-1.12-3.32Q136.81,30,138.34,19.19Zm-11.56,4.09c-.3,2.29-.64,4.57-1,6.85-.41,2.84-.83,5.67-1.2,8.51a4.2,4.2,0,0,1-.93,2,8.93,8.93,0,0,1-2.61,2.55,2.52,2.52,0,0,1-4-1.6,11.55,11.55,0,0,1-.31-3.47,31.86,31.86,0,0,1,2-11.27,7.47,7.47,0,0,1,3.44-4.34,6.13,6.13,0,0,1,4.24-.25C127.14,22.41,126.83,22.93,126.78,23.28Z' />
        <path d='M249.28,24.26c-1-4.65-3.56-8-8.16-9.53a18.14,18.14,0,0,0-9.83-.24c-5.21,1.19-8.77,4.52-11.1,9.13a28.78,28.78,0,0,0-2.81,16.87c.75,6.15,4.17,9.94,9.93,11.06,7.59,1.46,14.69-1.1,18.85-8.39,2.51-4.4,3.37-9.25,3.53-14.25A22.6,22.6,0,0,0,249.28,24.26Zm-12.9,15.37a6.69,6.69,0,0,1-2.13,3.13,3.9,3.9,0,0,1-3.79.69c-1.32-.46-1.72-1.65-1.93-2.9a23.63,23.63,0,0,1,.05-6,34,34,0,0,1,1.49-7.34,7.38,7.38,0,0,1,2.7-4.2c2.45-1.68,5.12-.5,5.49,2.41a18.66,18.66,0,0,1,.15,2.59A36.5,36.5,0,0,1,236.38,39.63Z' />
      </g>
    </svg>
  </a>
)

export default NavLogo
