import * as React from 'react'

import { Button } from '@mui/material'

import {
  MobileDrawer as BaseMobileDrawer,
  DrawerDivider,
  DrawerList,
  DrawerListItem,
  LandingCtaButton,
} from 'shared/components'
import { isLoggedIn } from 'shared/services'

import Footer from '../components/footer'
import NavBar from '../components/nav_bar'

const drawerWidth = 240

const NavBarButtons = () => (
  <React.Fragment>
    <Button
      href='/about/'
      variant='text'
      color='inherit'
      size='large'
      sx={{ display: { xs: 'none', sm: 'block' } }}
    >
      ¿Qué es?
    </Button>
    <Button
      href='/team/'
      variant='text'
      color='inherit'
      size='large'
      sx={{ display: { xs: 'none', sm: 'block' } }}
    >
      Nosotros
    </Button>
    <Button
      href='/faq/'
      variant='text'
      color='inherit'
      size='large'
      sx={{ display: { xs: 'none', sm: 'block' } }}
    >
      FAQs
    </Button>
    <Button
      href='/blog/'
      variant='text'
      color='inherit'
      size='large'
      sx={{ display: { xs: 'none', sm: 'block' } }}
    >
      Blog
    </Button>
    <LandingCtaButton
      id='header-cta'
      isLoggedIn={isLoggedIn}
    />
  </React.Fragment>
)

type LandingMobileDrawerProps = {
  open: boolean
  onClose: () => void
}

const MobileDrawer = ({
  open,
  onClose,
}: LandingMobileDrawerProps) => (
  <BaseMobileDrawer
    width={drawerWidth}
    open={open}
    onClose={onClose}
  >
    <DrawerList>
      <DrawerListItem
        text='Iniciar Sesión'
        href='/auth/login/'
      />
    </DrawerList>
    <DrawerDivider />
    <DrawerList>
      <DrawerListItem
        text='¿Qué es?'
        href='/about/'
      />
      <DrawerListItem
        text='Nosotros'
        href='/team/'
      />
      <DrawerListItem
        text='Preguntas Frecuentes'
        href='/faq/'
      />
      <DrawerListItem
        text='Blog'
        href='/blog/'
      />
    </DrawerList>
  </BaseMobileDrawer>
)

type LayoutProps = {
  showProgress?: boolean
  progressValue?: number
  children: React.ReactNode
}

export const Layout = ({
  showProgress,
  progressValue,
  children,
}: LayoutProps) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false)
  const toggleMobileDrawerOpen = () => {
    setMobileDrawerOpen(!mobileDrawerOpen)
  }
  const onMobileDrawerClose = () => {
    setMobileDrawerOpen(false)
  }

  return (
    <React.Fragment>
      <NavBar
        maxWidth='lg'
        onMenuButtonClick={toggleMobileDrawerOpen}
        showProgress={showProgress}
        progressValue={progressValue}
      >
        <NavBarButtons />
      </NavBar>
      <MobileDrawer
        open={mobileDrawerOpen}
        onClose={onMobileDrawerClose}
      />
      {children}
      <Footer />
    </React.Fragment>
  )
}

export default Layout
